/*
  @flow
*/

export type PropTypes = {
  answereeUserId?: string,
  answererRoleType?: string,
  assessmentRequestId?: string,
  onCompleted?: () => void,
  questionnaireId?: string,
  questionnaireQuestionSetId?: string,
  router: Object,
  specificQuestionnaireAssessmentTaken?: boolean,
}

const routeQuestionSet = ({
  answererRoleType,
  assessmentRequestId: assessment_request_id,
  answereeUserId: answeree_id,
  questionnaireQuestionSetId,
  questionnaireId: questionnaire_id,
  specificQuestionnaireAssessmentTaken,
  router,
  onCompleted,
}: PropTypes) => {
  let route

  switch (answererRoleType) {
    case 'CLINICIAN':
      route = {
        name: 'user_questionnaires_question_set_answer_by_clinician',
        params: {
          id: answeree_id,
          questionnaires_question_set_id: questionnaireQuestionSetId,
          answeree_id,
        },
      }
      break
    case 'SUPPORT_PERSON':
      route = {
        name: 'user_questionnaires_question_set_answer_answeree',
        params: {
          id: answeree_id,
          questionnaires_question_set_id: questionnaireQuestionSetId,
          answeree_id,
        },
      }
      break
    case 'INDIVIDUAL':
    default:
      // eslint-disable-next-line
      const routeName = !assessment_request_id
        ? 'user_questionnaires_question_set_answer'
        : questionnaire_id
          ? 'user_questionnaires_question_set_answer_assessment_request_questionnaire'
          : 'user_questionnaires_question_set_answer_assessment_request'
      route = {
        name: routeName,
        params: {
          questionnaires_question_set_id: questionnaireQuestionSetId,
          questionnaire_id,
          assessment_request_id,
          specificQuestionnaireAssessmentTaken,
        },
        query: { specificQuestionnaireAssessmentTaken },
      }
  }

  if (onCompleted) onCompleted()
  router.push(route)
}

export { routeQuestionSet }
