// @flow

import { camelCase, map, sortBy, union } from 'lodash/fp'

import type { IndividualsPageUser } from '../Individuals/IndividualsPage'

const STANDARD_COLUMN_SORT = [
  { header: 'name', label: 'Patient name' },
  { header: 'assignmentName', label: 'Clinicians' },
  { header: 'activeStatus', label: 'Status' },
  { header: 'invitedStatus', label: 'Status' },
  { header: 'removedStatus', label: 'Status' },
  { header: 'dischargedStatus', label: 'Status' },
]

const SCORES_COLUMN_SORT = [
  { header: 'recentActivity', label: 'Recent activity', order: 1 },
  { header: 'name', label: 'Notifications', order: 5, prioritise: true },
  { header: 'name', label: 'Patient name', order: 3 },
  { header: 'assignmentName', label: 'Clinicians', order: 8 },
]

const sortOptionsBy = ({ user, tableCategory }) => {
  const importantFormulas = user?.tenant?.important_formulas
  const importantColumns = map.convert({ cap: false })((item, index) => {
    const itemOrder = [7, 2, 4, 6]

    const prioritise = item.label === 'Suicidal thoughts and behaviours'

    return {
      header: 'formulaResult',
      label: item.label,
      id: item.formula.id,
      order: itemOrder[index],
      prioritise,
    }
  })(importantFormulas)

  const updatedScoresColumnSort = union(importantColumns, SCORES_COLUMN_SORT)
  const sortedUpdatedScoresColumnSort = sortBy('order')(updatedScoresColumnSort)

  const sortedOptions =
    tableCategory === 'scores'
      ? sortedUpdatedScoresColumnSort
      : STANDARD_COLUMN_SORT

  // Add unique sortCode for the source of truth
  const sortedOptionsWithSortCode = map(option => ({
    ...option,
    sortCode: `${option.header}-${camelCase(option.label)}`,
  }))(sortedOptions)

  return sortedOptionsWithSortCode
}

type UseIndividualTableSortProps = {
  currentUser?: IndividualsPageUser,
  tableCategory: string,
}

const useIndividualTableSort = (props: UseIndividualTableSortProps) => {
  const { currentUser, tableCategory } = props

  const sortOptions = sortOptionsBy({ user: currentUser, tableCategory })

  return {
    sortOptions,
  }
}

export default useIndividualTableSort
