// @flow

import React, { Component } from 'react'
import { connect } from 'react-fela'
import Form from 'react-jsonschema-form'
import { createFragmentContainer, graphql } from 'react-relay'
import classNames from 'classnames'
import { withRouter } from 'found'
import debounce from 'lodash/debounce'
import { find, flowRight, isEqual, isNil, pickBy } from 'lodash/fp'

import { StaticQuestionnaireInformationLoader } from 'components/StaticQuestionnaireInformation'
import StickyQuestionnaireHeader from 'components/StickyQuestionnaireHeader'
import FinishedAlertModal from 'components/UserQuestionnairesQuestionSet/FinishedAlertModal'
import { handleGraphqlErrors } from 'components/UserQuestionnairesQuestionSet/services/errors'
import UserQuestionnaireQuestionSetSkipModal from 'components/UserQuestionnairesQuestionSet/UserQuestionnaireQuestionSetSkipModal'
import ResponsiveLayout from 'react-ui/components/Layout/ResponsiveLayout'
import Section from 'react-ui/components/Section'
import { commit as commitUserQuestionnairesQuestionSetAnswer } from 'mutations/UserQuestionnairesQuestionSetAnswer'
import { commit as commitUserQuestionnairesQuestionSetSkip } from 'mutations/UserQuestionnairesQuestionSetSkip'
import { commit as commitUserQuestionnairesQuestionSetSubmit } from 'mutations/UserQuestionnairesQuestionSetSubmit'
import { buildData, buildJsonSchema, buildUiSchema } from 'services/formSchema'
import createComponentId from 'shared/services/id'
import Sentry from 'shared/services/sentry'
import { linkTo } from 'platform_web/services/defineLinkTo'

import { type FormType, prepareAnswers, transformErrors } from './services'
import { ErrorListTemplate } from './templates/ErrorListTemplate'
import { FieldTemplate } from './templates/FieldTemplate'
import { ObjectFieldTemplate } from './templates/ObjectFieldTemplate'
import QuestionnaireFooterLoader from './UserQuestionnaireQuestionSetFooter'
import { widgets } from './widgets'

import { type FelaPropsType } from 'react-ui/typing'
import type { UserQuestionnairesQuestionSet_answer_set } from './__generated__/UserQuestionnairesQuestionSet_answer_set.graphql'
import { type UserQuestionnairesQuestionSet_user_questionnaires_question_set } from './__generated__/UserQuestionnairesQuestionSet_user_questionnaires_question_set.graphql'

export type UserQuestionnairesQuestionSetType = UserQuestionnairesQuestionSet_user_questionnaires_question_set
export type QuestionSetType = $PropertyType<
  UserQuestionnairesQuestionSetType,
  'question_set',
>

export type QuestionsType = $PropertyType<
  UserQuestionnairesQuestionSetType,
  'questions',
>

type PropsType = FelaPropsType & {
  answer_set: UserQuestionnairesQuestionSet_answer_set,
  componentId?: string,
  homepage_path: string,
  match: Object,
  +relay: Object,
  +role: Object,
  roleType: ?string,
  +router: Object,
  +user_questionnaires_question_set: UserQuestionnairesQuestionSetType, // This is a temporary prop that will be used with feature toggling to swap out the old and new questionnaire design
}

type StateType = {
  busy: boolean, // Indicates the form is sending answer data to the server
  data: {
    [key: string]: string,
  },
  errors?: any,
  inputId?: string, // the input ID that user has touched
  invalidInteraction: boolean, // Indicates that there is no active QAS
  promise?: Promise<void>,
  showCustomErrorList: boolean,
  showSkipReasonSelectBox: ?boolean, // Indicates the footer whether SelectBox is visible or not
  skip_reason?: any,
  submitting: boolean,
}

const defaultId = createComponentId(__filename)

function validateIfSelectBoxIsShown(user_questionnaires_question_set) {
  const { visible_questions, skippable } = user_questionnaires_question_set

  if (!visible_questions) return null
  const { edges } = visible_questions

  const check_if_all_questions_are_answered = question =>
    question.answer === null || question.answer === undefined

  // $DisableFlow TODO: check into why the objects and array are returning as different types
  const nodes: NonMaybeQuestions = (edges || [])
    .filter(Boolean)
    .map(qs => ({ answer: qs.answer, ...qs.node }))

  return (
    skippable &&
    nodes.filter(qs => qs.required).some(check_if_all_questions_are_answered)
  )
}

export class UserQuestionnairesQuestionSet extends Component<
  PropsType,
  StateType,
> {
  // this is the existential type to get a fix for QUOLL-153, but this component will be obsolete soon
  OFT: *

  constructor(props: PropsType) {
    super(props)
    this.OFT = ObjectFieldTemplate()
    this.state = {
      busy: false,
      data: buildData(props.user_questionnaires_question_set.visible_questions),
      submitting: false,
      showCustomErrorList: false,
      showSkipReasonSelectBox: validateIfSelectBoxIsShown(
        props.user_questionnaires_question_set,
      ),
      skip_reason: undefined,
      errors: undefined,
      invalidInteraction: false,
    }
  }

  componentWillReceiveProps(nextProps: PropsType) {
    const newQuestionSetLoaded =
      nextProps.user_questionnaires_question_set.id !==
      this.props.user_questionnaires_question_set.id
    if (newQuestionSetLoaded) {
      window.scrollTo(0, 0)
      this.reinitState(nextProps)
      return
    }

    // this is where visible questions reloading gets to
    const {
      props: { user_questionnaires_question_set: { visible_questions } },
      state: { errors },
    } = this
    const {
      user_questionnaires_question_set: {
        visible_questions: next_visible_questions,
      },
    } = nextProps
    // visible_questions is the only field that we have to check
    // answer field can either be null or have a value
    // length of the whole visible_questions can change if we trigger a conditional question
    if (!isEqual(visible_questions, next_visible_questions)) {
      this.setState({
        busy: false,
        data: buildData(next_visible_questions),
        // filter errors to what new visible questions have
        errors: (errors || []).filter(error =>
          find({ node: { id: (error?.property || '').slice(1) } })(
            next_visible_questions?.edges || [],
          ),
        ),
      })
    }
  }

  // NOTE: because we need to reuse this component even when the route is changed,
  // therefore, we need to reset the states
  reinitState(props: PropsType) {
    this.setState({
      busy: false,
      data: buildData(props.user_questionnaires_question_set.visible_questions),
      inputId: undefined,
      submitting: false,
      showCustomErrorList: false,
      showSkipReasonSelectBox: validateIfSelectBoxIsShown(
        props.user_questionnaires_question_set,
      ),
      skip_reason: undefined,
      errors: undefined,
      invalidInteraction: false,
    })
  }

  handleUpdate = (form: FormType) => {
    const {
      props: {
        user_questionnaires_question_set: {
          questionnaires_question_set,
          user,
          user_questionnaire,
        },
        relay: { environment },
      },
    } = this

    const answers_data = prepareAnswers(form)

    const variables = {
      input: {
        answers_data,
        questionnaires_question_set_id: questionnaires_question_set.id,
        assessment_request_id: user_questionnaire.assessment_request?.id,
        questionnaire_id: user_questionnaire.questionnaire?.id,
        answeree_id: user.id,
      },
    }

    const onError = error => {
      Sentry.captureMessage(
        'Answer did not save due to error sending mutation',
        { extra: { variables } },
      )
      Sentry.captureException(error)
    }

    this.setState({
      busy: true,
    })

    // <begin:promise>
    // NOTE: Promise is used here make sure only one request should be processed at a time.
    // Even if for some reason user has managed to trigger a second request,
    // it should wait for the current request to finish.
    const clearPromise = () => this.setState({ promise: undefined })

    const setPromise = () => {
      this.setState({
        promise: new Promise((resolve, reject) =>
          commitUserQuestionnairesQuestionSetAnswer({
            environment,
            variables,
            onError,
            onCompleted: (mutationResponse, errors) => {
              if (errors) {
                handleGraphqlErrors(errors, 'UQQS answer', () =>
                  this.setState({ invalidInteraction: true }),
                )
                return reject(clearPromise())
              }

              this.setState({
                busy: false,
              })

              if (
                mutationResponse &&
                mutationResponse.userQuestionnairesQuestionSetAnswer
              ) {
                const {
                  user_questionnaires_question_set,
                } = mutationResponse.userQuestionnairesQuestionSetAnswer

                this.setState({
                  showSkipReasonSelectBox: validateIfSelectBoxIsShown(
                    user_questionnaires_question_set,
                  ),
                })
              }
              return resolve(clearPromise())
            },
          }),
        ),
      })
    }

    if (this.state.promise) {
      // NOTE: this means we have a current request running
      return this.state.promise.then(setPromise)
    }
    return setPromise()
    // <end:promise>
  }

  debouncedHandleUpdate = debounce(this.handleUpdate, 750)

  handleFocus = (id: string) => {
    // NOTE: only text-like input (e.g. text/number/email/etc) will trigger this handler
    this.setState({ inputId: id })
  }

  handleBlur = () => {
    // NOTE: only text-like input (e.g. text/number/email/etc) will trigger this handler
    this.setState({ inputId: undefined })
    // NOTE: Execute the submission once the user blurs away from the input instead of waiting 750ms
    // This is to prevent user from clicking the Next button
    // when this answer submission hasn't finished.
    this.debouncedHandleUpdate.flush()
  }

  handleChange = (form: FormType) => {
    const hasValue = value => !isNil(value)
    const sameAnswer = isEqual(
      pickBy(hasValue, form.formData),
      pickBy(hasValue, this.state.data),
    )

    if (sameAnswer) {
      this.setState({ errors: form.errors })
      return
    }

    this.setState({ errors: form.errors, data: form.formData })
    this.debouncedHandleUpdate(form)

    // @see this.handleFocus & this.handleBlur
    const canFlushUpdate = !this.state.inputId
    if (canFlushUpdate) {
      this.debouncedHandleUpdate.flush()
    }
  }

  handleFormError = (errors: any) => {
    this.setState({ errors })
  }

  handleSubmitClick = () => {
    const { showSkipReasonSelectBox, skip_reason } = this.state

    if (
      (showSkipReasonSelectBox && skip_reason === undefined) ||
      !showSkipReasonSelectBox
    ) {
      this.setState({
        showCustomErrorList: true,
      })
    }
  }

  handleSkipReasonSelect = (value: string) => {
    this.setState({
      skip_reason: value,
    })
  }

  handleSkipThenSubmit = (value: string, trigger: Function) => {
    this.setState(
      {
        skip_reason: value,
      },
      () => {
        trigger()
      },
    )
  }

  handleSubmit = (form: FormType) => {
    const {
      props: {
        user_questionnaires_question_set: uqQuestionSet,
        user_questionnaires_question_set: {
          user_questionnaire,
          skippable,
          questionnaires_question_set: { id: questionnaires_question_set_id },
          user: { id: answeree_id },
        },
        role,
        roleType,
        relay: { environment },
        router,
        match,
      },
    } = this

    const { specificQuestionnaireAssessmentTaken } = match.location.query
    const { skip_reason } = this.state
    let variables

    const handleError = error => {
      Sentry.captureMessage(
        'Question Set could not be submitted due to error sending mutation',
        { extra: { variables } },
      )
      Sentry.captureException(error)
      this.setState({ submitting: false, busy: false })
    }

    const handleOnCompleted = action => {
      if (!action) {
        throw new Error('Must receive response, you might be offline')
      }

      linkTo({
        uqQuestionSet,
        role,
        router,
        roleType,
        specificQuestionnaireAssessmentTaken,
      })
    }

    if (skippable && skip_reason) {
      variables = {
        input: {
          questionnaires_question_set_id,
          answeree_id,
          assessment_request_id: user_questionnaire.assessment_request?.id,
          questionnaire_id: user_questionnaire.questionnaire?.id,
          skip_reason,
        },
      }

      this.setState({ submitting: true })

      commitUserQuestionnairesQuestionSetSkip({
        environment,
        onError: handleError,
        onCompleted: (userQuestionnairesQuestionSetSkip, errors) => {
          if (errors) {
            handleGraphqlErrors(errors, 'UQQS skip', () =>
              this.setState({ invalidInteraction: true }),
            )
            return
          }
          handleOnCompleted(userQuestionnairesQuestionSetSkip)
        },
        variables,
      })
    } else {
      // We are saving answers again during the submission to ensure the answers are correctly saved. We have a strange bug where sometimes it will not save.
      variables = {
        input: {
          answers_data: prepareAnswers(form),
          questionnaires_question_set_id,
          answeree_id,
          assessment_request_id: user_questionnaire.assessment_request?.id,
          questionnaire_id: user_questionnaire.questionnaire?.id,
        },
      }

      this.setState({ submitting: true })
      commitUserQuestionnairesQuestionSetSubmit({
        environment,
        onError: handleError,
        onCompleted: (userQuestionnairesQuestionSetSubmit, errors) => {
          if (errors) {
            handleGraphqlErrors(errors, 'UQQS submit', () =>
              this.setState({ invalidInteraction: true }),
            )
            return
          }

          handleOnCompleted(userQuestionnairesQuestionSetSubmit)
        },
        variables,
      })
    }
  }

  render() {
    const {
      props: {
        componentId = defaultId,
        user_questionnaires_question_set,
        user_questionnaires_question_set: {
          question_set: questionSet,
          user_questionnaire,
          user_questionnaire: { finished: uqFinished },
          finished: uqqsFinished,
        },
        role,
        router,
        styles,
        answer_set,
        homepage_path,
      },
      state: {
        data,
        submitting,
        busy,
        showCustomErrorList,
        showSkipReasonSelectBox,
        skip_reason,
        errors,
        invalidInteraction,
      },
    } = this

    const questions = user_questionnaires_question_set.visible_questions
    const schema = buildJsonSchema({ questionSet, questions })
    const uiSchema = buildUiSchema(questions)
    const hasFormError = errors && errors.length > 0
    const hasErrorsWhenSubmittingForm = showCustomErrorList && hasFormError
    const hasNotSelectedSkipReason = !skip_reason
    const submissionDisabled = hasNotSelectedSkipReason && hasFormError

    // 1. Prevent the user from accessing or interacting with a UserQuestionnaire that is already finished
    // 2. Prevent the user from navigating back to a previously completed question set (Keep them progressing through the questionnaire)
    if (uqFinished || invalidInteraction || uqqsFinished) {
      return (
        <FinishedAlertModal
          uqQuestionSet={user_questionnaires_question_set}
          role={role}
          invalidInteraction={invalidInteraction}
          homepage_path={homepage_path}
        />
      )
    }

    return (
      <ResponsiveLayout
        light="false"
        headerButton={
          showSkipReasonSelectBox && (
            <UserQuestionnaireQuestionSetSkipModal
              busy={busy}
              skipOnHeader
              handleNext={this.handleSubmit}
              handleSkipSelect={this.handleSkipReasonSelect}
              skipReason={skip_reason}
              submitting={submitting}
              userQuestionnaire={user_questionnaire}
              userQuestionnaireQuestionSet={user_questionnaires_question_set}
            />
          )
        }
        router={router}
        customHeader={
          <StickyQuestionnaireHeader
            userQuestionnairesQuestionSet={user_questionnaires_question_set}
          />
        }
        hasEmptyHeader
        description={user_questionnaires_question_set.question_set.description}
      >
        <Section color="lightGrey" center noPadding>
          <StaticQuestionnaireInformationLoader questionSet={questionSet} />
        </Section>

        <Section color="white" center>
          <div className={classNames(componentId, styles.wrappedContainer)}>
            <Form
              FieldTemplate={FieldTemplate}
              ObjectFieldTemplate={this.OFT}
              formData={data}
              noHtml5Validate
              liveValidate
              noValidate={!!skip_reason}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onError={this.handleFormError}
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              schema={schema}
              showErrorList={false}
              transformErrors={transformErrors}
              uiSchema={uiSchema}
              widgets={widgets}
              disabled={submitting || busy}
            >
              {// Added a nil check for error to prevent flow errors
              hasErrorsWhenSubmittingForm && (
                <ErrorListTemplate errors={errors || []} />
              )}
              <QuestionnaireFooterLoader
                answer_set={answer_set}
                busy={busy}
                onSkipReasonSelect={this.handleSkipThenSubmit}
                onSubmit={this.handleSubmitClick}
                // TODO: Remove the skip reason once the skip goes to header
                showSkipReasonSelectBox={showSkipReasonSelectBox}
                skipReason={skip_reason}
                submissionDisabled={submissionDisabled}
                submitting={submitting}
                userQuestionnaire={user_questionnaire}
                userQuestionnaireQuestionSet={user_questionnaires_question_set}
              />
            </Form>
          </div>
        </Section>
      </ResponsiveLayout>
    )
  }
}

graphql`
  fragment UserQuestionnairesQuestionSet_Question_question on Question {
    id
    conditionals {
      question_option {
        id
        question {
          id
        }
      }
    }
    condition_scenario {
      equation
    }
    jsonSchema {
      ... on JsonSchemaMultichoice {
        enum
        enumNames
        enumValues
      }
      ... on JsonSchemaMultiselect {
        enum
        enumNames
        enumValues
      }
      ... on JsonSchemaInteger {
        maximum
        minimum
      }
      ... on JsonSchemaImperialLength {
        feet_max_value
        feet_min_value
        inches_max_value
        inches_min_value
      }
      ... on JsonSchemaString {
        minLength
        maxLength
      }
    }
    kind
    label
    image {
      url
      alt_txt
    }
    required
    uiSchema {
      uiWidget
    }
  }
`
graphql`
  fragment UserQuestionnairesQuestionSet_Answer_question on UserQuestionnairesQuestionSetQuestionEdge {
    answer {
      ... on AnswerInteger {
        id
        value
      }
      ... on AnswerImperialLength {
        id
        value
      }
      ... on AnswerString {
        id
        value
      }
      ... on AnswerMultichoice {
        id
        value
      }
      ... on AnswerMultiselect {
        id
        value
      }
    }
  }
`

const withRelay = component =>
  createFragmentContainer(component, {
    user_questionnaires_question_set: graphql`
      fragment UserQuestionnairesQuestionSet_user_questionnaires_question_set on UserQuestionnairesQuestionSet {
        ...QuestionSetCard_questionSet
        ...UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet
        ...StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet
        id
        all_questions_answered
        finished
        skippable
        user_questionnaire {
          finished
          assessment_request {
            id
          }
          questionnaire {
            id
          }
          ...UserQuestionnaireQuestionSetFooter_userQuestionnaire
        }
        next_user_questionnaires_question_set {
          questionnaires_question_set {
            id
          }
        }
        questionnaires_question_set {
          id
          questionnaire {
            id
          }
        }
        question_set {
          ...StaticQuestionnaireInformation_questionSet
          description
          label
        }
        questions {
          edges {
            ...UserQuestionnairesQuestionSet_Answer_question @relay(mask: false)
            node {
              ...UserQuestionnairesQuestionSet_Question_question
                @relay(mask: false)
            }
          }
        }
        user {
          id
        }
        visible_questions {
          edges {
            ...UserQuestionnairesQuestionSet_Answer_question @relay(mask: false)
            node {
              ...UserQuestionnairesQuestionSet_Question_question
                @relay(mask: false)
            }
          }
        }
      }
    `,
    answer_set: graphql`
      fragment UserQuestionnairesQuestionSet_answer_set on AnswerSetSkipReasonEnumType {
        ...UserQuestionnaireQuestionSetFooter_answer_set
      }
    `,
  })

const styleRules = ({ theme }) => ({
  wrappedContainer: {
    textAlign: 'left',
    maxWidth: theme.userQuestMaxWidth,
    marginTop: '40px',
    marginRight: 'auto',
    marginBottom: '10rem',
    marginLeft: 'auto',
  },
  questionSetWrapper: {
    padding: `calc(${theme.Grid.gutter} * 9) 0 calc(${
      theme.Grid.gutter
    } * 3.75)`,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.lightBackground,
  },
})

export const UserQuestionnairesQuestionSetLoader = flowRight([
  withRelay,
  withRouter,
  connect(styleRules),
])(UserQuestionnairesQuestionSet)
